<template>
  <div class="tabs-material-subtitle">
    <Container>
      <h2>Informations générales</h2>
    </Container>
  </div>

  <div class="key-values key-values--footer-bottom">
    <KeyValue
      v-if="protocol && protocol.partage_accepte"
      table
      label="Partage accepté"
      :value="protocol.partage_accepte ? 'Oui' : 'Non'"
    />

    <KeyValue table label="Titre" required type="input">
      <Input id="titre" />
    </KeyValue>

    <KeyValue
      table
      label="Statut"
      :value="
        protocol && protocol.statut ? protocol.statut.designation : 'Brouillon'
      "
    />

    <KeyValue
      v-if="isShared"
      table
      label="Année de récolte"
      :value="protocol?.annee_recolte?.valeur"
    />
    <KeyValue v-else table required label="Année de récolte" type="input">
      <SelectExtended
        v-if="helperService.getReferentialByUid('anneerecolte')"
        id="annee_recolte_id"
        :apiParams="{ sort: 'valeur.ASC' }"
        optionKey="id"
        optionValue="valeur"
        :apiEndpoint="`dictionnaire/referentiel/${helperService.getReferentialByUid('anneerecolte')}/valeur`"
      />
    </KeyValue>

    <KeyValue v-if="isShared" required table label="Cultures">
      <template v-if="protocol.cultures.length > 0">
        <div class="tags">
          <MiniTag
            v-for="culture in protocol.cultures"
            :key="culture.id"
            :id="culture.uid"
            :text="culture.nom"
          />
        </div>
      </template>
      <template v-else>
        <span></span>
      </template>
    </KeyValue>
    <KeyValue v-else table required  label="Cultures" type="input">
      <SelectExtended
        id="cultures"
        optionKey="uid"
        optionValue="nom"
        apiEndpoint="culture"
        :apiParams="{ limit: 0, sort: 'nom.ASC' }"
        multiple
        chips
      />
    </KeyValue>

    <KeyValue
      v-if="isShared"
      table
      label="Thématique"
      :value="protocol.thematique.designation"
    />
    <KeyValue v-else required table label="Thématique" type="input">
      <SelectExtended
        id="thematique_uid"
        v-model="schema.thematique_uid"
        optionKey="uid"
        optionValue="designation"
        apiEndpoint="dictionnaire/thematique"
        @update:modelValue="onInputChange($event)"
      />
    </KeyValue>

    <KeyValue
      v-if="isShared"
      table
      label="Sous-thèmes"
      :value="protocol.sous_thematiques.map((c) => c.nom)"
      type="select"
    />
    <KeyValue v-else table label="Sous-thèmes" type="input">
      <SelectExtended
        v-if="ste"
        :key="componentKey"
        id="sous_thematiques"
        optionKey="uid"
        optionValue="nom"
        :apiEndpoint="ste"
        multiple
        chips
      />
    </KeyValue>

    <KeyValue
      v-if="isShared"
      table
      label="Objectifs"
      :value="protocol.objectifs"
    />
    <KeyValue v-else table label="Objectifs" type="input">
      <Input type="textarea" id="objectifs" />
    </KeyValue>

    <KeyValue
      v-if="isShared"
      table
      label="Type Protocole"
      :value="protocol.type.designation"
    />
    <KeyValue v-else required table label="Type Protocole" type="input">
      <SelectExtended
        id="type_uid"
        optionKey="uid"
        optionValue="designation"
        apiEndpoint="dictionnaire/type/protocole"
      />
    </KeyValue>

      <KeyValue
        v-show="!initialIsBpe && isShared"
        table
        label="Essai BPE"
        :value="initialIsBpe ? 'Oui' : 'Non'"
      />
      <KeyValue v-show="initialIsBpe || !isShared" table label="Essai BPE" type="input">
        <Radio
          id="bpe"
          :items="[
          { label: 'Oui', value: true },
          { label: 'Non', value: false },
        ]"
          inputStyle="inline"
          @update:modelValue="
          (event) => {
            protocol.bpe = event;
            isBpe = protocol;
          }
        "
        />
      </KeyValue>

    <KeyValue
      v-if="isShared"
      table
      label="Déclaration du protocole"
      :value="protocol.declaration_protocole?.valeur"
    />
    <KeyValue v-else table label="Déclaration du protocole" type="input">
      <SelectExtended
        v-if="helperService.getReferentialByUid('declarationprotocole')"
        id="declaration_protocole_id"
        v-model="schema.declaration_protocole_id"
        optionKey="id"
        optionValue="valeur"
        :apiEndpoint="`dictionnaire/referentiel/${helperService.getReferentialByUid('declarationprotocole')}/valeur`"
      />
    </KeyValue>

    <KeyValue
      v-if="isShared"
      table
      label="Essai en prestation de service"
      :value="protocol.prestation_service ? 'Oui' : 'Non'"
    />
    <KeyValue v-else table label="Essai en prestation de service" type="input">
      <Radio
        id="prestation_service"
        :items="[
          { label: 'Oui', value: true },
          { label: 'Non', value: false },
        ]"
        inputStyle="inline"
      />
    </KeyValue>

    <KeyValue
      v-if="isShared"
      table
      label="Essai AB"
      :value="protocol.agriculture_biologique ? 'Oui' : 'Non'"
    />
    <KeyValue v-else table label="Essai AB" type="input">
      <Radio
        id="agriculture_biologique"
        :items="[
          { label: 'Oui', value: true },
          { label: 'Non', value: false },
        ]"
        inputStyle="inline"
      />
    </KeyValue>

    <KeyValue
      v-if="isShared"
      table
      label="Code national"
      :value="protocol.code_national"
    />
    <KeyValue v-else table label="Code national" type="input">
      <Input id="code_national" />
    </KeyValue>

    <KeyValue
      v-if="isShared"
      table
      child
      label="Qualification"
      :value="protocol.national_qualification"
    />
    <KeyValue v-else table child label="Qualification" type="input">
      <Input id="national_qualification" />
    </KeyValue>

    <KeyValue
      v-if="isShared"
      table
      child
      label="Type"
      :value="protocol.type_qualification"
    />
    <KeyValue v-else table child label="Type" type="input">
      <Input id="type_qualification" />
    </KeyValue>

    <KeyValue
      v-if="isShared"
      table
      child
      label="Objectif"
      :value="protocol.objectif_qualification"
    />
    <KeyValue v-else table child label="Objectif" type="input">
      <Input id="objectif_qualification" />
    </KeyValue>

    <KeyValue
      v-if="isShared"
      table
      label="Code régional"
      :value="protocol.code_regional"
    />
    <KeyValue v-else table label="Code régional" type="input">
      <Input id="code_regional" />
    </KeyValue>

    <KeyValue
      v-if="isShared"
      table
      label="Code donneur d'ordre"
      :value="protocol.donneur_ordre_code"
    />
    <KeyValue
      v-else
      :required="JSON.parse(isBpe)"
      table
      label="Code donneur d'ordre"
      type="input"
    >
      <Input id="donneur_ordre_code" />
    </KeyValue>

    <KeyValue table label="Code entité" type="input">
      <Input id="code_entite" />
    </KeyValue>

    <KeyValue
      v-if="isShared"
      table
      label="Donneur d'ordre"
      :value="protocol.donneur_ordre"
    />
    <KeyValue
      v-else
      :required="JSON.parse(isBpe)"
      table
      label="Donneur d'ordre"
      type="input"
    >
      <Input id="donneur_ordre" />
    </KeyValue>

    <KeyValue
      table
      :required="JSON.parse(isBpe)"
      label="Contact du donneur d'ordre"
      type="input"
    >
      <Input id="donneur_ordre_contact" />
    </KeyValue>

    <KeyValue
      v-if="isShared"
      table
      label="N° Devis"
      :value="protocol.numero_devis"
    />
    <KeyValue
      v-else
      :required="JSON.parse(isBpe)"
      table
      label="N° Devis"
      type="input"
    >
      <Input id="numero_devis" />
    </KeyValue>
  </div>
</template>

<script>
import SelectExtended from '@/components/form/SelectExtended.vue'
import KeyValue from '@/components/form/KeyValue.vue'
import Input from '@/components/form/Input.vue'
import Radio from '@/components/form/Radio.vue'
import Container from '@/components/layout/Container.vue'
import MiniTag from '@/components/base/MiniTag.vue'

export default {
  name: 'GeneralInformationAddEditSubview',

  components: {
    Radio,
    SelectExtended,
    KeyValue,
    Input,
    Container,
    MiniTag,
  },

  props: {
    pageTitle: {
      type: String,
    },
    validationSchema: {
      type: [Array, Object],
      default: undefined,
    },
    modelValue: {
      type: Boolean,
      default: false,
    },
  },

  data(props) {
    const { validationSchema } = props
    const vs = validationSchema[0]
    const { isSubmitting, errors } = this.formService.initFrom(vs)

    return {
      protocol: {},
      form: {
        references: [],
        thematiques: [],
      },
      initialIsBpe: null,
      schema: {},
      sousThemesEndpoint: 'dictionnaire/sous-thematique',
      componentKey: 0,
      step: 1,
      routeName: this.$route.name,
      onSubmit: false,
      vs,
      isSubmitting,
      errors,
    }
  },

  emits: [
    'set-step',
    'populate',
    'set-route',
    'form-submitted',
    'set-submitted',
  ],

  computed: {
    ste: {
      get() {
        return this.sousThemesEndpoint
      },
      async set(v) {
        const thematiques = await this.getThematiques()
        let thematique = {}
        if (v) {
          if ('key' in v) {
            thematique = thematiques
              .filter((item) => item.uid === v.key)
              .shift()
          } else if ('id' in v) {
            thematique = thematiques
              .filter((item) => item.uid === v.uid)
              .shift()
          } else {
            thematique = {}
          }
        }

        if ('id' in thematique && thematique.uid !== 'MULTITHEME') {
          this.sousThemesEndpoint = `dictionnaire/thematique/${thematique.id}/sous-thematique`
        } else {
          this.sousThemesEndpoint = 'dictionnaire/sous-thematique'
        }
        this.componentKey += 1
      },
    },
    isBpe: {
      get() {
        if ('bpe' in this.protocol) {
          return this.protocol?.bpe
        }
        return false
      },
      set(v) {
        this.protocol = v
        this.componentKey += 1
      },
    },
    isShared() {
      return (
        this.protocol.date_partage !== null && this.protocol.partage_accepte
      )
    },
  },

  watch: {
    modelValue(newVal) {
      // watch it
      if (newVal) {
        const { values } = this.formService
        const schema = this.protocolService.getSchema()
        const datas = {}

        Object.keys(this.vs.fields).forEach((node) => {
          if (node in values) {
            datas[node] = values[node]
          } else if (node in schema) {
            datas[node] = datas[node] ? datas[node] : schema[node]
          }
        })

        this.formService.setValues(datas)

        this.formService.validate(this.formService.values).then((errors) => {
          if (errors.valid) {
            this.$emit('form-submitted', this.formService.values)
            this.initialIsBpe = datas.bpe
          } else {
            this.formService.handleErrors(true)
            this.$emit('set-submitted')
          }
        })
      }
    },
  },

  async mounted() {
    this.emitter.emit('open-loader')
    const routeParams = {}

    this.schema.thematique_uid = {}

    if (this.$route.params.id) {
      routeParams.id = this.$route.params.id
      if (this.protocol.thematique) {
        this.ste = this.protocol.thematique
      }
    }

    if (this.$route.name.includes('Edit')) {
      this.nextRouteName = 'protocolExperimentImplementationEdit'
    } else {
      this.nextRouteName = 'protocolExperimentImplementationAdd'
    }

    this.$emit('set-step', this.step)
    this.$emit('set-route', {
      routeName: this.routeName,
      previousRouteName: null,
      nextRouteName: this.nextRouteName,
      routeParams,
    })
    if (this.$route.params.id) {
      this.protocol = await this.getProtocol()
    }
    this.emitter.emit('close-loader')
  },

  methods: {
    onInputChange(event) {
      if (event) {
        this.ste = event
      } else {
        this.schema.thematique_uid = event
        this.ste = event
      }
    },

    async getThematiques() {
      const response = await this.fetchService.get('dictionnaire/thematique')
      const thematiques = await response.data

      return thematiques
    },

    async getProtocol() {
      const response = await this.fetchService.get(
        `protocole/${this.$route.params.id}`,
      )
      const protocol = await response.data
      this.initialIsBpe = protocol.bpe
      this.$emit('populate', protocol)
      return protocol
    },
  },
}
</script>

<style scoped>
</style>
